import {useEffect, useState} from "react";
import {ApiClient} from "../apiClient";
import {Container} from "./utilities/Container";
import {motion} from "framer-motion";
import {QrScanner} from "@yudiel/react-qr-scanner";
import {InputField} from "./utilities/InputField";
import Button from "./utilities/Button";

export default function VehicleSelector({onSelect}) {
    const [vehicles, setVehicles] = useState([]);
    const [licensePlate, setLicensePlate] = useState('');

    useEffect(() => {
        ApiClient.get('/api/vehicles').then(response => {
            if (response.status === 200) {
                response.json().then(json => {
                    setVehicles(json);
                    localStorage.setItem('vehicles', JSON.stringify(json));
                })
            } else {
                throw new Error('Could not fetch vehicles');
            }
        }).catch(error => {
            console.error(error);
            const vehicles = localStorage.getItem('vehicles');
            if (vehicles) {
                setVehicles(JSON.parse(vehicles));
            }
        })

    }, [])

    return (
        <Container>
            {window.globalSettings.vehicleSelector === 'list' && (
                <>
                    <h1 className={'text-center text-3xl font-bold mb-5'}>Vælg køretøj</h1>
                    <div className={'grid grid-cols-1 gap-5'}>
                        {vehicles.map((vehicle, index) => (
                            <motion.div
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                transition={{duration: 0.8}}
                                key={vehicle.code}>
                                <div className={'bg-white rounded-lg shadow p-5 cursor-pointer'}
                                     onClick={(e) => {
                                         onSelect(vehicle)
                                     }}>
                                    <div className={'flex items-center justify-center text-center'}>
                                        <div>
                                            {vehicle.description}
                                            <br/>
                                            {vehicle.code}
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </>
            )}
            {window.globalSettings.vehicleSelector === 'scanQr' && (
                <>
                    <h1 className={'text-center text-3xl font-bold mb-5'}>Scan køretøj</h1>
                    <QrScanner
                        onResult={(result) => {
                            const vehicle = vehicles.find(vehicle => vehicle.code === result.text);
                            if (vehicle) {
                                onSelect(vehicle);
                            }
                        }}
                        onError={(err) => {
                            console.error(err, 'error');
                        }}/>
                    <div>
                        <p className={'text-center mt-5 mb-3 text-lg font-bold'}>Tast nummerplade</p>
                        <InputField value={licensePlate} onChange={(e) => setLicensePlate(e.target.value)}/>
                        <Button type={'primary'} className={'block w-full'} onClick={(e) => {
                            e.preventDefault();
                            const vehicle = vehicles.find(vehicle => vehicle.code.toLowerCase() === licensePlate.toLowerCase());
                            if (vehicle) {
                                onSelect(vehicle);
                            }
                        }}>Vælg</Button>
                    </div>
                </>
            )}
        </Container>
    )
}