import {motion} from "framer-motion";

export default function LoadTransition({children}) {
    return (
        <motion.main
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.8}}
        >
            {children}
        </motion.main>
    )
}