export class ApiClient {
    static headers = {
        'Content-Type': 'application/json',
        'Authorization': ''
    };
    
    // Set headers
    static setHeaders() {
        if (localStorage.getItem('token')) {
            this.headers.Authorization = `${localStorage.getItem('token')}`;
        }
    }
    
    static async get(url) {
        this.setHeaders();
        
        return await fetch(url, {
            method: 'GET',
            headers: this.headers
        });
    }
    
    static async post(url, body) {
        this.setHeaders();
        
        return await fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: this.headers
        });
    }
    
    static async put(url, body) {
        this.setHeaders();
        
        return await fetch(url, {
            method: 'PUT',
            headers: this.headers,
            body: JSON.stringify(body)
        });
    }
    
    static async delete(url) {
        this.setHeaders();
        
        return await fetch(url, {
            method: 'DELETE',
            headers: this.headers
        });
    }
}