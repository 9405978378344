import Login from "./views/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./views/Dashboard";
import ScanPalletsForTransport from "./views/ScanPalletsForTransport";
import Developer from "./views/Developer";
import Drive from "./views/Drive";

const AppRoutes = [
    {
        index: true,
        element: <Login/>,
    },
    {
        path: '/dashboard',
        element: <ProtectedRoute><Dashboard></Dashboard></ProtectedRoute>
    },
    {
        path: '/scan-for-transport',
        element: <ProtectedRoute><ScanPalletsForTransport></ScanPalletsForTransport></ProtectedRoute>
    },
    {
        path: '/drive',
        element: <ProtectedRoute><Drive/></ProtectedRoute>
    },
    {
        path: '/developer',
        element: <ProtectedRoute><Developer/></ProtectedRoute>
    }
];

export default AppRoutes;
