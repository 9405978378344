import {Component} from "react";

export class Container extends Component {
    render() {
        return (
            <div className={'w-full px-3 md:px-0 md:w-[500px] mx-auto ' + this.props.className}>
                {this.props.children}
            </div>
        )
    }
}