import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ShortcutCard({icon, title, description, onClick}) {
    return (
        <div className={'bg-white rounded p-3 shadow cursor-pointer mb-5 cursor-pointer'} onClick={onClick}>
            <div className={'flex items-center justify-center'}>
                <FontAwesomeIcon icon={icon} className={'text-4xl text-gray-500'}/>
            </div>
            <div className={'mt-2'}>
                <h3 className={'text-xl font-bold'}>{title}</h3>
                <p className={'text-gray-500'}>{description}</p>
            </div>
        </div>
    )
}