export default function ProtectedRoute({children}) {

    const token = localStorage.getItem('token');
    const expires = localStorage.getItem('tokenExpires');

    if (!token || new Date(expires) < new Date()) {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpires');
        localStorage.removeItem('driverId');
        localStorage.removeItem('driverName');

        window.location.href = '/';
    }

    return (
        <>
            {children}
        </>
    )
}