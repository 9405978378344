import LoadTransition from "../components/LoadTransition";
import BackButton from "../components/BackButton";
import Button from "../components/utilities/Button";
import {Container} from "../components/utilities/Container";
import {useEffect, useRef, useState} from "react";
import PalletService, {PalletStatus} from "../services/PalletService";
import {QrScanner} from "@yudiel/react-qr-scanner";
import CustomAlert from "../components/CustomAlert";
import {useNavigate} from "react-router-dom";

export default function Drive() {
    const [scannedPallets, setScannedPallets] = useState([]);
    const [scanActive, setScanActive] = useState(false);
    const [currentAction, setCurrentAction] = useState(null);
    const [alertErrorMessage, setAlertErrorMessage] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState(null);
    const [deliverToCustomerActive, setDeliverToCustomerActive] = useState(false);
    const [currentPallet, setCurrentPallet] = useState(null);
    const navigate = useNavigate();
    const photoRef = useRef(null);
    const fileRef = useRef(null);

    function drawImageScaled(img, ctx) {
        var canvas = ctx.canvas;
        var hRatio = canvas.width / img.width;
        var vRatio = canvas.height / img.height;
        var ratio = Math.min(hRatio, vRatio);
        var centerShift_x = (canvas.width - img.width * ratio) / 2;
        var centerShift_y = (canvas.height - img.height * ratio) / 2;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, img.width, img.height, centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
    }

    useEffect(() => {
        setScannedPallets(PalletService.getScannedPallets());
    }, [])

    return (<LoadTransition>
        <Container>
            <CustomAlert errorMessage={alertErrorMessage} onOk={() => {
                setAlertErrorMessage(null);
            }}/>
            {confirmDialog !== null && (<CustomAlert errorMessage={confirmDialog.message} onOk={confirmDialog.onOk}
                                                     onCancel={confirmDialog.onCancel}/>)}
            {deliverToCustomerActive && (<>
                <div className={'fixed top-0 left-0 w-full h-full bg-white text-center overflow-y-auto py-5'}>
                    <p className={'font-bold text-3xl mb-5'}>Scan ud til kunde</p>
                    <p>Du er ved at scanne palle {currentPallet} ud.
                        <br/>
                        Tag et billede for at dokumentere afleveringen.
                    </p>
                    <input type="file" className={'hidden'} ref={fileRef} onChange={(e) => {
                        let file = e.target.files[0];
                        console.log(file, 'file')

                        // Convert the file to base64 text using quality = 0.7
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            console.log(reader.result);
                            let img = new Image();
                            img.src = reader.result;
                            img.onload = () => {
                                let canvas = photoRef.current;
                                let ctx = canvas.getContext('2d');
                                ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height)
                                let dataURL = canvas.toDataURL('image/jpeg', 0.7);

                                PalletService.logPalletEvent(currentPallet, PalletStatus.DELIVERED_TO_CUSTOMER, dataURL);
                                PalletService.removeScannedPallet(currentPallet);
                                setScannedPallets(PalletService.getScannedPallets());
                                setDeliverToCustomerActive(false);
                                setScanActive(false);
                            }
                        };
                    }}/>
                    <canvas ref={photoRef} className={'hidden'}></canvas>
                    <Button className={'mt-5'} onClick={() => {
                        setDeliverToCustomerActive(false);
                        setScanActive(true);
                    }}>Fortryd</Button>
                    <Button className={'ml-1 mt-5'} onClick={() => {
                        let photo = photoRef.current;

                        fileRef.current.click();
                    }}>Tag billede</Button>
                </div>
            </>)}
            <BackButton to={'/scan-for-transport'}/>
            <div className={'text-center'}>
                <h1 className={'text-4xl font-bold'}>Kørsel</h1>
            </div>
            {scanActive && (<QrScanner onError={(e) => {
                console.error(e);
            }} onResult={(result) => {
                if (alertErrorMessage !== null) {
                    return;
                }

                if (!result.text.startsWith('DX')) {
                    setAlertErrorMessage('QR-koden er ikke gyldig.');
                    return;
                }

                if (currentAction !== 'scanPallet' && scannedPallets.filter(p => p === result.text).length <= 0) {
                    setAlertErrorMessage('Pallen er ikke scannet ind til levering.');
                    return;
                }

                setScanActive(false);

                let message = '';
                let status = null;
                if (currentAction === 'deletePallet') {
                    message = 'Er du sikker på at du vil slette pallen?';
                    status = PalletStatus.REMOVED;
                } else if (currentAction === 'deliverToCustomer') {
                    setDeliverToCustomerActive(true);
                    setCurrentPallet(result.text)
                    return;
                } else if (currentAction === 'deliverToDepot') {
                    message = 'Er du sikker på at du vil levere pallen til depotet?';
                    status = PalletStatus.DELIVERED_TO_DEPOT;
                }

                if (currentAction === "scanPallet") {
                    if (scannedPallets.filter(p => p === result.text).length > 0) {
                        setAlertErrorMessage('Pallen er allerede scannet.');
                        return;
                    }

                    const palletId = result.text;

                    PalletService.addScannedPallet(palletId);
                    PalletService.logPalletEvent(palletId, PalletStatus.LOADED);
                    setScannedPallets(PalletService.getScannedPallets());
                } else {
                    setConfirmDialog({
                        message: message, onOk: () => {
                            PalletService.removeScannedPallet(result.text);
                            PalletService.logPalletEvent(result.text, status);
                            setScannedPallets(PalletService.getScannedPallets());
                            setConfirmDialog(null);
                        }, onCancel: () => {
                            setConfirmDialog(null);
                        }
                    });
                }
                return;
            }}/>)}
            <ScanButton buttonText={'Tilføj palle'} onClick={() => {
                setCurrentAction('scanPallet');
                setScanActive(true);
            }}/>
            <ScanButton buttonText={'Scan palle ud til kunde'} onClick={() => {
                setCurrentAction('deliverToCustomer');
                setScanActive(true);
            }}/>
            <ScanButton buttonText={'Scan palle ud til depot'} onClick={() => {
                setCurrentAction('deliverToDepot');
                setScanActive(true);
            }}/>
            <ScanButton buttonText={'Slet palle'} onClick={() => {
                setCurrentAction('deletePallet');
                setScanActive(true);
            }}/>
            <ScanButton buttonText={'Stop kørsel'} onClick={() => {
                setCurrentAction('stopDriving');
                setConfirmDialog({
                    message: 'Er du sikker på at du vil stoppe kørslen?', onOk: () => {
                        scannedPallets.forEach(pallet => {
                            PalletService.logPalletEvent(pallet, PalletStatus.RETURNED);
                            localStorage.setItem('isDriving', false);
                        })

                        PalletService.logPalletEvent('', PalletStatus.STOP_DRIVING);
                        PalletService.resetEntryNo();

                        PalletService.clearScannedPallets();
                        setScannedPallets(PalletService.getScannedPallets());
                        setConfirmDialog(null);

                        localStorage.removeItem('isDriving');

                        PalletService.clearSyncedPalletEvents();

                        navigate('/dashboard');
                    }, onCancel: () => {
                        setConfirmDialog(null);
                    }
                })
            }}/>
            <div className={'text-center mt-5'}>
                Paller tilbage
                <div className={'font-bold'}>
                    {scannedPallets.length}
                </div>
            </div>
        </Container>
    </LoadTransition>)
}

function ScanButton({buttonText, onClick}) {
    return (<div className={'text-center mt-5'}>
        <Button className={'block w-full'} onClick={(e) => {
            onClick();
        }}>{buttonText}</Button>
    </div>)
}