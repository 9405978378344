import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { Layout } from "./components/Layout";
import "./custom.css";
import { AnimatePresence } from "framer-motion";
import PalletService, { PalletStatus } from "./services/PalletService";

export default function App() {
  const location = useLocation();

  let wakeLock = null;
  const requestWakeLock = async () => {
    try {
      wakeLock = await navigator.wakeLock.request();
      wakeLock.addEventListener("release", () => {
        console.log("Screen Wake Lock released:", wakeLock.released);
      });
      console.log("Screen Wake Lock released:", wakeLock.released);
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  requestWakeLock();

  // Request permissions for coordinates if not already granted
  navigator.permissions.query({ name: "geolocation" }).then(function (result) {
    if (result.state === "granted") {
      console.log("Permission already granted");
    } else if (result.state === "prompt") {
      console.log("Permission not granted, requesting permission...");
      navigator.geolocation.getCurrentPosition(
        () => {
          console.log("Permission granted");
        },
        (error) => {
          console.error(error);
        }
      );
    } else if (result.state === "denied") {
      console.log("Permission denied");
    }
  });

  return (
    <Layout>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
        </Routes>
      </AnimatePresence>
    </Layout>
  );
}
