import {faArrowLeft, faScanner, faSignOut, faWrench} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Container} from "../components/utilities/Container";
import {useNavigate} from "react-router-dom";
import ShortcutCard from "../components/ShortcutCard";
import {motion} from "framer-motion";
import LoadTransition from "../components/LoadTransition";

export default function Dashboard({}) {
    const navigate = useNavigate();

    let shortcuts = [
        {
            icon: faScanner,
            title: 'Indscan',
            description: 'Scan paller ind til levering',
            onClick: () => {
                navigate('/scan-for-transport')
            }
        },
        {
            icon: faSignOut,
            title: 'Log ud',
            description: 'Log ud af appen',
            onClick: () => {
                localStorage.removeItem('token');
                localStorage.removeItem('tokenExpires');
                localStorage.removeItem('driverId');
                localStorage.removeItem('driverName');
                localStorage.removeItem('vehicleId');
                localStorage.removeItem('vehicles');
                localStorage.removeItem('scannedPallets');

                window.location.href = '/';
            }
        }
    ];
    
    if (window.location.hostname === 'localhost') {
        shortcuts.push({
            icon: faWrench,
            title: 'Udvikler',
            description: 'Udvikler menu',
            onClick: () => {
                navigate('/developer')
            }
        })
    }

    return (
        <>
            <LoadTransition>
                <Container>
                    {shortcuts.map((shortcut, index) => {
                        return (
                            <ShortcutCard key={index} {...shortcut}></ShortcutCard>
                        )
                    })}
                    <div className={'text-center text-xs text-gray-500'}>Version {process.env.REACT_APP_VERSION}</div>
                </Container>
            </LoadTransition>
        </>
    )
}