import {Component, useEffect} from "react";

export default function Button({type, children, className, onClick}) {
    let allClassNames = 'font-bold py-2 px-4 rounded';
    if (type === 'primary') {
        allClassNames += ' bg-black text-white';
    } else if (type === 'brand') {
        allClassNames += ' bg-brand text-white';
    } else if (type === 'secondary') {
        allClassNames += ' bg-gray-300 text-black';
    } else if (type === 'danger') {
        allClassNames += ' bg-red-500 text-white';
    } else if (type === 'success') {
        allClassNames += ' bg-green-500 text-white';
    } else if (type === 'warning') {
        allClassNames += ' bg-yellow-500 text-white';
    } else if (type === 'info') {
        allClassNames += ' bg-blue-500 text-white';
    } else if (type === 'light') {
        allClassNames += ' bg-gray-100 text-black';
    } else if (type === 'dark') {
        allClassNames += ' bg-gray-800 text-white';
    } else if (type === 'link') {
        allClassNames += ' bg-transparent text-black';
    } else {
        allClassNames += ' bg-black text-white';
    }

    if (className) {
        allClassNames += ' ' + className;
    }

    return (
        <button className={allClassNames} onClick={(e) => {
            if (onClick) {
                e.preventDefault();
                onClick(e);
            }
        }}>{children}</button>
    )
}