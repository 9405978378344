import React, {Component} from 'react';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <div className={'bg-black p-3 mb-10'}>
                    <img src={'/drinx-logo.png'} alt={'Drinx Logo'} className={'mx-auto max-w-[150px]'}/>
                </div>
                {this.props.children}
            </div>
        );
    }
}
