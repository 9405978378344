import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/pro-solid-svg-icons";

export default function BackButton({to}) {
    const navigate = useNavigate();

    return (
        <div className={'flex items-center justify-center -mt-5 mb-5'}>
            <div className={'inline-block'} onClick={(e) => {
                e.preventDefault();
                navigate(to)
            }}>
                <FontAwesomeIcon icon={faArrowLeft} className={'text-1xl mr-1 text-gray-500'}/>
                Tilbage
            </div>
        </div>
    )
}