import {Component} from "react";

export class InputField extends Component {
    constructor(props) {
        super(props);
        
        this.className = '';
        
        if (this.props.style === 'dark') {
            this.className += ' bg-gray-900 text-white border-gray-800';
        }

        if (this.props.className) {
            this.className += ' ' + this.props.className;
        }
    }

    render() {
        return (
            <>
                {this.props.label && (
                    <label className={'block mb-1'}>{this.props.label}</label>
                )}
                <input
                    type={this.props.type || 'text'}
                    name={this.props.name}
                    className={'w-full border border-gray-300 rounded-md p-3 mb-3' + this.className}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.props.onChange}
                />
            </>
        )
    }
}