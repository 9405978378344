import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import PalletService, { PalletStatus } from "./services/PalletService";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const worker = new Worker("./worker.js");
worker.postMessage("handlePalletLog");
let lastRun = null;
worker.onmessage = (e) => {
  console.log("message received", e);
  console.log("last run", lastRun);

  if (!lastRun || lastRun.getTime() + 1000 * 60 * 2 <= new Date().getTime()) {
    lastRun = new Date();

    if (e.data === "handlePalletLog") {
      if (localStorage.getItem("isDriving") === "true") {
        PalletService.logPalletEvent("", PalletStatus.DRIVING);
      } else {
        PalletService.executePalletLogQueue();
      }
    }
  }
};

// Global settings
window.globalSettings = {
  vehicleSelector: "scanQr",
};

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });

          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
