import { ApiClient } from "../apiClient";

export default class PalletService {
  static getScannedPallets() {
    const pallets = localStorage.getItem("scannedPallets");
    if (pallets) {
      return JSON.parse(pallets);
    } else {
      return [];
    }
  }

  static addScannedPallet(palletId) {
    const pallets = this.getScannedPallets();
    pallets.push(palletId);
    localStorage.setItem("scannedPallets", JSON.stringify(pallets));
  }

  static removeScannedPallet(palletId) {
    const pallets = this.getScannedPallets();
    const index = pallets.findIndex((p) => p.id === palletId);
    pallets.splice(index, 1);
    localStorage.setItem("scannedPallets", JSON.stringify(pallets));
  }

  static clearScannedPallets() {
    localStorage.removeItem("scannedPallets");
  }

  static getEntryNo() {
    let entryNo = localStorage.getItem("entryNo");
    if (entryNo) {
      entryNo = parseInt(entryNo);
      entryNo++;
    } else {
      entryNo = 1;
    }

    localStorage.setItem("entryNo", entryNo.toString());

    return entryNo;
  }

  static resetEntryNo() {
    localStorage.removeItem("entryNo");
  }

  static logPalletEvent(palletId, status, img = null) {
    const logEvent = (coordinates) => {
      const palletEvents = localStorage.getItem("palletEvents");
      if (palletEvents) {
        const events = JSON.parse(palletEvents);

        events.push({
          palletId: palletId,
          entryNo: this.getEntryNo(),
          status: status,
          timestamp: new Date().toISOString(),
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          driver: localStorage.getItem("driverId"),
          vehicleCode: localStorage.getItem("vehicleId"),
          img: img,
        });
        localStorage.setItem("palletEvents", JSON.stringify(events));
      } else {
        let entryNo = this.getEntryNo();
        localStorage.setItem(
          "palletEvents",
          JSON.stringify([
            {
              palletId: palletId,
              entryNo: entryNo,
              status: status,
              timestamp: new Date().toISOString(),
              latitude: coordinates.latitude,
              longitude: coordinates.longitude,
              driver: localStorage.getItem("driverId"),
              vehicleCode: localStorage.getItem("vehicleId"),
              img: img,
            },
          ])
        );
      }
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        logEvent(position.coords);

        this.executePalletLogQueue();
      },
      (error) => {
        console.error(error);
        alert("Kunne ikke finde din position. Prøv igen.");
      }
    );
  }

  static clearSyncedPalletEvents() {
    const palletEvents = this.getPalletEvents();
    const unsyncedEvents = palletEvents.filter((i) => !i.synced);
    localStorage.setItem("palletEvents", JSON.stringify(unsyncedEvents));
  }

  static getPalletEvents() {
    const palletEvents = localStorage.getItem("palletEvents");
    if (palletEvents) {
      return JSON.parse(palletEvents);
    } else {
      return [];
    }
  }

  static executePalletLogQueue() {
    console.log(window.palletLogExecuting, "window.palletLogExecuting");

    if (window.palletLogExecuting === true) {
      return;
    }

    window.palletLogExecuting = true;
    let events = this.getPalletEvents().filter((i) => !i.synced);

    this.clearSyncedPalletEvents();

    if (events.length > 0) {
      events.forEach((event) => {
        const postEvent = {
          palletId: event.palletId,
          entryNo: event.entryNo,
          status: event.status,
          timestamp: event.timestamp,
          latitude: event.latitude,
          longitude: event.longitude,
          driver: event.driver,
          vehicleCode: event.vehicleCode,
          img: event.img,
        };

        console.log(postEvent, "post event");

        ApiClient.post("/api/pallets/events", postEvent)
          .then((response) => {
            if (response.status === 200) {
              const palletEvents = this.getPalletEvents();
              const index = palletEvents.findIndex(
                (i) =>
                  i.palletId === event.palletId && i.entryNo === event.entryNo
              );

              if (index !== -1) {
                palletEvents[index].synced = true;
              }

              localStorage.setItem(
                "palletEvents",
                JSON.stringify(palletEvents)
              );
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            events = this.getPalletEvents().filter((i) => i.synced);

            if (events.length > 0) {
              events.forEach((event) => {
                // Delete up until the highest synced entry number for the pallet
                const palletEvents = this.getPalletEvents();
                if (
                  palletEvents.filter((i) => i.palletId === event.palletId)
                    .length > 1
                ) {
                  const highestSyncedEntryNo = Math.max(
                    ...events
                      .filter(
                        (i) => i.vehicleCode === event.vehicleCode && i.synced
                      )
                      .map((i) => i.entryNo)
                  );
                  const index = palletEvents.findIndex(
                    (i) =>
                      i.palletId === event.palletId &&
                      i.entryNo === highestSyncedEntryNo
                  );
                  palletEvents.splice(0, index + 1);
                  localStorage.setItem(
                    "palletEvents",
                    JSON.stringify(palletEvents)
                  );
                }
              });
            }

            window.palletLogExecuting = false;
          });
      });
    } else {
      events = this.getPalletEvents().filter((i) => i.synced);

      if (events.length > 0) {
        events.forEach((event) => {
          // Delete up until the highest synced entry number for the pallet
          const palletEvents = this.getPalletEvents();
          if (
            palletEvents.filter((i) => i.palletId === event.palletId).length > 1
          ) {
            const highestSyncedEntryNo = Math.max(
              ...events
                .filter((i) => i.vehicleCode === event.vehicleCode && i.synced)
                .map((i) => i.entryNo)
            );
            const index = palletEvents.findIndex(
              (i) =>
                i.palletId === event.palletId &&
                i.entryNo === highestSyncedEntryNo
            );
            palletEvents.splice(0, index + 1);
            localStorage.setItem("palletEvents", JSON.stringify(palletEvents));
          }
        });
      }

      window.palletLogExecuting = false;
    }
  }
}

// Export enum with pallet statuses
export const PalletStatus = {
  LOADED: "LOADED",
  REMOVED: "REMOVED",
  DELIVERED_TO_CUSTOMER: "DELIVERED_TO_CUSTOMER",
  DELIVERED_TO_DEPOT: "DELIVERED_TO_DEPOT",
  DRIVING: "DRIVING",
  START_DRIVING: "START_DRIVING",
  RETURNED: "RETURNED",
  STOP_DRIVING: "STOP_DRIVING",
};
