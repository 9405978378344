export default function ErrorAlert({errors}) {
    return (
        <>
            {errors.length > 0 && (
                <div className={'bg-red-500 text-white p-3 rounded-md mb-3'}>
                    <ul>
                        {errors.map((error, index) => {
                            return (
                                <li key={index}>{error}</li>
                            )
                        })}
                    </ul>
                </div>
            )}
        </>
    )
}