import {Container} from "../components/utilities/Container";
import {InputField} from "../components/utilities/InputField";
import {ApiClient} from "../apiClient";
import ErrorAlert from "../components/utilities/ErrorAlert";
import {useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import LoadTransition from "../components/LoadTransition";
import Button from "../components/utilities/Button";

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);

    const navigate = useNavigate();

    const token = localStorage.getItem('token');
    const expires = localStorage.getItem('tokenExpires');

    if (token && new Date(expires) > new Date()) {
        window.location.href = '/dashboard';
    }

    return (
        <>
            <LoadTransition>
                <div
                    className={`bg-[url('/public/login-bg.png')] bg-cover bg-center absolute left-0 top-0 w-full h-full -z-10`}>
                    <div className={'absolute w-full h-full bg-black opacity-50 bg-blend-overlay'}></div>
                </div>
                <Container>
                    <h1 className={'text-center text-3xl font-bold mb-5 text-white'}>Chauffør App</h1>
                    <form onSubmit={async (e) => {
                        e.preventDefault();

                        const response = await ApiClient.post('/api/auth/login', {
                            driverId: username,
                            password: password
                        });

                        const json = await response.json();

                        if (response.status === 200) {
                            localStorage.setItem('token', json.token.token);
                            localStorage.setItem('tokenExpires', json.token.expires);
                            localStorage.setItem('driverId', json.token.authData);
                            localStorage.setItem('driverName', json.driver.name);

                            navigate('/dashboard')
                        } else {
                            console.log(json);
                            if (json.errors) {
                                setErrors(json.errors)
                            }
                        }
                    }}>
                        <ErrorAlert errors={errors}></ErrorAlert>
                        <div className="mb-3">
                            <InputField
                                placeholder={'Indtast dit chauffør ID'}
                                style={'dark'}
                                name={'username'}
                                onChange={(e) => {
                                    setUsername(e.target.value)
                                }}/>
                        </div>
                        <div className="mb-3">
                            <InputField
                                placeholder={'Indtast dit password'}
                                name={'password'}
                                style={'dark'}
                                type={'password'}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}/>
                        </div>
                        <div className={'text-center'}>
                            <Button type={'brand'} className={'w-full'}>Login</Button>
                        </div>
                    </form>
                </Container>
            </LoadTransition>
        </>
    )
}
